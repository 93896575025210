import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { JobAlertForm } from './JobAlertForm'
import { FirebaseAppProviderWithAppCheck } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

const queryClient = new QueryClient()

export function JobAlertFormApp({ filters }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProviderWithAppCheck
      config={config.firebase}
      name={config.firebase.projectId}
      reCaptchaSiteKey={config.reCaptchaSiteKey}
    >
      <QueryClientProvider client={queryClient}>
        <JobAlertForm {...{ filters }} />
      </QueryClientProvider>
    </FirebaseAppProviderWithAppCheck>
  )
}
